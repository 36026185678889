
.video-call-tutor {
    position: fixed;
    inset: 0;
    background-color: #121214;
    overflow: hidden;
  }
  
  /* Loading spinner animation */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @keyframes progress-bar {
    0% { width: 0; }
    100% { width: 100%; }
  }
  
  .animate-progress-bar {
    animation: progress-bar 30s linear infinite;
  }
  
  @keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes scale-in {
    from { transform: scale(0.95); opacity: 0; }
    to { transform: scale(1); opacity: 1; }
  }
  
  .animate-fade-in {
    animation: fade-in 0.2s ease-out;
  }
  
  .animate-scale-in {
    animation: scale-in 0.3s ease-out;
  }
  
  /* Floating action buttons */
  .floating-actions {
    position: fixed;
    bottom: 100px;
    left: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    z-index: 50;
  }
  
  .action-button {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border: none;
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    transition: all 0.2s ease;
  }
  
  .action-button {
    background-color: #2563eb;
  }
  
  .action-button:hover {
    background-color: #1d4ed8;
    transform: translateY(-2px);
  }
  
  .action-button.active {
    background-color: #1e40af;
  }
  
  .action-button.create-room {
    background-color: #4f46e5;
  }
  
  .action-button.create-room:hover {
    background-color: #4338ca;
  }
  
  /* Breakout panel */
  .breakout-panel {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 300px;
    background-color: #1a1a1f;
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.3);
    z-index: 40;
    display: flex;
    flex-direction: column;
    animation: slide-in 0.3s ease-out;
    color: white;
  }
  
  @keyframes slide-in {
    from { transform: translateX(100%); }
    to { transform: translateX(0); }
  }
  
  .panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .panel-header h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
  }
  
  .panel-header button {
    background: none;
    border: none;
    color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .panel-header button:hover {
    color: white;
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .panel-content {
    flex: 1;
    overflow-y: auto;
    padding: 16px;
  }
  
  .section-title {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
    margin: 16px 0 8px;
    font-weight: 500;
    text-transform: uppercase;
  }
  
  .room-item {
    border-radius: 8px;
    margin-bottom: 12px;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.05);
    transition: all 0.2s ease;
  }
  
  .room-item:hover {
    background-color: rgba(255, 255, 255, 0.08);
  }
  
  .room-item.active {
    background-color: rgba(37, 99, 235, 0.2);
    border: 1px solid rgba(37, 99, 235, 0.4);
  }
  
  .room-item.full {
    opacity: 0.7;
  }
  
  .room-item button {
    display: block;
    width: 100%;
    text-align: left;
    background: none;
    border: none;
    color: white;
    padding: 12px;
    cursor: pointer;
  }
  
  .room-details {
    padding: 12px;
    display: flex;
    flex-direction: column;
  }
  
  .room-name {
    font-weight: 500;
    margin-bottom: 4px;
  }
  
  .room-info {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
  }
  
  .time-remaining {
    font-size: 11px;
    color: #f59e0b;
    margin-top: 6px;
  }
  
  .room-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 12px 12px;
  }
  
  .join-button {
    background-color: #2563eb;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 6px 12px;
    font-size: 12px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .join-button:hover {
    background-color: #1d4ed8;
  }
  
  .status-badge {
    font-size: 11px;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: rgba(37, 99, 235, 0.2);
    color: #60a5fa;
  }
  
  .status-badge.full {
    background-color: rgba(156, 163, 175, 0.2);
    color: #9ca3af;
  }
  
  .no-rooms {
    text-align: center;
    padding: 24px 0;
    color: rgba(255, 255, 255, 0.4);
  }
  
  /* Modal styling */
  .breakout-modal {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  .modal-section {
    display: flex;
    flex-direction: column;
  }
  
  .section-label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
    color: #374151;
  }
  
  .slider-container {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  
  .slider {
    flex: 1;
    height: 4px;
    appearance: none;
    background-color: #e5e7eb;
    border-radius: 2px;
    cursor: pointer;
  }
  
  .slider::-webkit-slider-thumb {
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #2563eb;
    cursor: pointer;
    border: 2px solid white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .slider-value {
    width: 32px;
    text-align: center;
    font-weight: 500;
  }
  
  .select-input {
    padding: 8px 12px;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    font-size: 14px;
    outline: none;
    transition: border-color 0.2s;
  }
  
  .select-input:focus {
    border-color: #2563eb;
    box-shadow: 0 0 0 1px rgba(37, 99, 235, 0.2);
  }
  
  .room-preview {
    margin-top: 8px;
    padding-top: 16px;
    border-top: 1px solid #e5e7eb;
  }
  
  .preview-title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 12px;
    color: #374151;
  }
  
  .room-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
    gap: 8px;
  }
  
  .room-preview-item {
    background-color: #e0edff;
    border: 1px solid #bfdbfe;
    border-radius: 6px;
    padding: 8px;
    text-align: center;
  }
  
  .room-number {
    font-size: 12px;
    font-weight: 500;
    margin: 0 0 4px;
    color: #1e40af;
  }
  
  .room-slots {
    font-size: 11px;
    margin: 0;
    color: #3b82f6;
  }
  
  .modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid #e5e7eb;
  }
  
  .cancel-button, .create-button {
    padding: 8px 16px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .cancel-button {
    background-color: #f3f4f6;
    color: #4b5563;
    border: 1px solid #d1d5db;
  }
  
  .cancel-button:hover {
    background-color: #e5e7eb;
  }
  
  .create-button {
    background-color: #2563eb;
    color: white;
    border: none;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .create-button:hover {
    background-color: #1d4ed8;
  }
  
  .create-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .spinner {
    animation: spin 1s linear infinite;
    width: 16px;
    height: 16px;
  }
  
  .spinner circle {
    stroke: currentColor;
    stroke-linecap: round;
    stroke-dasharray: 60, 200;
    stroke-dashoffset: 0;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .breakout-panel {
      width: 100%;
    }
  }