@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom CSS to hide the scrollbar */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* -----------------------------------------------------------------chat-overrides.css --------------------------------------------*/

/* ----------------------------------------------Hide scrollbar for the message list ---------------------------------*/
.str-chat__list {
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}

.str-chat__list::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for the main chat container */
.str-chat__main-panel {
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}

.str-chat__main-panel::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for thread if present */
.str-chat__thread {
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}

.str-chat__thread::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for message text container */
.str-chat__message-text {
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}

.str-chat__message-text::-webkit-scrollbar {
  display: none !important;
}

/* //-----------------------------messages chat-----------------------------// */
/* Outgoing (my messages) */
.str-chat__message--me .str-chat__message-text {
  /* Background & text */
  background-color: #14b82c !important;
  color: #ffff !important;

  /* Border style */
  border: 1px solid #12551e !important; /* or your desired color */

  /* Round corners */
  border-radius: 12px !important;

  /* Make one corner NOT rounded.
     For example, top-right corner is square. */
  border-bottom-right-radius: 0 !important;

  /* Optional: add spacing inside bubble */
  padding: 8px !important;
}

.str-chat__message--other .str-chat__message-text {
  /* Background & text */
  background-color: #f6f6f6 !important;
  color: black !important;

  /* Border style */
  border: 1px solid #d1d1d1 !important; /* or your desired color */

  /* Round corners */
  border-radius: 12px !important;

  /* Make one corner NOT rounded.
     For example, top-left corner is square. */
  border-bottom-left-radius: 0 !important;

  /* Optional: add spacing inside bubble */
  padding: 8px !important;
}

/* str-video__video str-video__video--mirror */
.str-video__video--mirror {
  width: 100% !important;
}