/* src/components/CallPreview.css */

body,
html {
  height: 100%;
  width: 100%;
}

.str-video {
  background-color: #121212;
  color: #ffffff;
  height: 100dvh;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
  max-width: 100%;
}

/* Dark Theme Base Colors */
:root {
  --dark-bg-primary: #121212;
  --dark-bg-secondary: #1E1E1E;
  --dark-bg-tertiary: #2D3748;
  --dark-text-primary: #f7fafc59;
  --dark-text-secondary: #A0AEC0;
  --dark-border: #2D3748;
  --dark-accent: #3b83f66e;
  --dark-accent-hover: #2564eb73;
  --dark-input-bg: #2D3748;
  --dark-card-bg: #1A202C;
  --dark-hover: #2D3748;
  --dark-message-me: #3B82F6;
  --dark-message-other: #2D3748;
}

/* Dark theme base styling */
.dark-theme {
  background-color: var(--dark-bg-primary);
  color: var(--dark-text-primary);
}

/* Call preview container */
.enhanced-call-preview {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: var(--dark-bg-primary);
}

.str-video__speaker-layout__wrapper{
  height: 100vh;
}
.str-chat__message--me .str-chat__message-text{
  background-color: transparent !important;
  color: white !important;
}

/* Call layout arrangement */
.call-layout {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
}

/* Main video area */
.main-video-area {
  flex: 1;
  position: relative;
  height: 100%;
  transition: width 0.3s ease;
  background-color: var(--dark-bg-primary);
}

.main-video-area.with-sidebar {
  width: calc(100% - 320px);
}

/* Sidebar for chat or participants */
.call-sidebar {
  width: 320px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 10;
}

.dark-theme-sidebar {
  background-color: var(--dark-bg-secondary);
  border-left: 1px solid var(--dark-border);
}

/* Sidebar header */
.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
}

.dark-theme-sidebar-header {
  background-color: var(--dark-bg-tertiary);
  border-bottom: 1px solid var(--dark-border);
}

.sidebar-header h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.dark-theme-sidebar-header h3 {
  color: var(--dark-text-primary);
}

.close-sidebar {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.dark-theme-sidebar-header .close-sidebar {
  color: var(--dark-text-secondary);
}

.dark-theme-sidebar-header .close-sidebar:hover {
  background-color: var(--dark-hover);
  color: var(--dark-text-primary);
}

/* Containers for chat and participants */
.chat-container,
.participants-container {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

/* Make sure the chat takes full height */
.chat-container .str-chat,
.chat-container .str-chat__container {
  height: 100%;
  background-color: var(--dark-bg-secondary);
}

/* Floating controls */
.floating-controls {
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  gap: 8px;
  z-index: 10;
}

.control-btn {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.control-btn:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

.control-btn.active {
  background-color: var(--dark-accent);
}

.control-btn.has-notification {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0.7);
  }
  70% {
    box-shadow: 0 0 0 6px rgba(59, 130, 246, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(59, 130, 246, 0);
  }
}

.notification-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #EF4444;
  color: white;
  font-size: 10px;
  font-weight: bold;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Dark theme custom message input */
.custom-message-input {
  padding: 12px 16px;
}

.dark-theme-input {
  background-color: var(--dark-bg-tertiary);
  border-top: 1px solid var(--dark-border);
}

.message-input-form {
  position: relative;
}

.message-input-container {
  display: flex;
  align-items: center;
  border-radius: 24px;
  padding: 4px 12px;
}

.dark-theme-input .message-input-container {
  background-color: var(--dark-input-bg);
  border: 1px solid var(--dark-border);
}

.message-input-field {
  flex: 1;
  border: none;
  outline: none;
  padding: 8px 12px;
  font-size: 14px;
  background: transparent;
}

.dark-theme-input .message-input-field {
  color: var(--dark-text-primary);
}

.dark-theme-input .message-input-field::placeholder {
  color: var(--dark-text-secondary);
}

.send-button, .attachment-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 50%;
}

.dark-theme-input .send-button, 
.dark-theme-input .attachment-button {
  color: var(--dark-accent);
}

.dark-theme-input .send-button:hover, 
.dark-theme-input .attachment-button:hover {
  background-color: rgba(59, 130, 246, 0.2);
}

.dark-theme-input .send-button:disabled {
  color: var(--dark-text-secondary);
  cursor: not-allowed;
}

.attachment-menu {
  position: absolute;
  bottom: 100%;
  left: 10px;
  margin-bottom: 8px;
  border-radius: 8px;
  display: flex;
  padding: 8px;
  gap: 8px;
  z-index: 10;
}

.dark-theme-input .attachment-menu {
  background-color: var(--dark-card-bg);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.attachment-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 8px;
  border-radius: 8px;
  background: none;
  border: none;
  cursor: pointer;
}

.dark-theme-input .attachment-option {
  color: var(--dark-text-secondary);
}

.dark-theme-input .attachment-option:hover {
  background-color: var(--dark-hover);
  color: var(--dark-text-primary);
}

.attachment-option span {
  font-size: 12px;
}

/* Custom message styling for dark theme */
.custom-message-container {
  position: relative;
  margin-bottom: 8px;
}

.reactions-selector-container {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 4px;
  z-index: 10;
}

.reactions-selector {
  display: flex;
  border-radius: 24px;
  padding: 6px;
  gap: 4px;
}

.dark-theme-message .reactions-selector {
  background-color: var(--dark-card-bg);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.reaction-button {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

.dark-theme-message .reaction-button:hover {
  background-color: var(--dark-hover);
}

.message-action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: none;
  border: none;
  cursor: pointer;
}

.dark-theme-message .message-action-button {
  color: var(--dark-text-secondary);
}

.dark-theme-message .message-action-button:hover {
  background-color: var(--dark-hover);
  color: var(--dark-text-primary);
}

/* Dark theme custom channel header */
.custom-channel-header {
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dark-theme-header {
  background-color: var(--dark-bg-tertiary);
  border-bottom: 1px solid var(--dark-border);
}

.channel-header-info {
  display: flex;
  flex-direction: column;
}

.channel-name {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.dark-theme-header .channel-name {
  color: var(--dark-text-primary);
}

.member-count {
  font-size: 12px;
}

.dark-theme-header .member-count {
  color: var(--dark-text-secondary);
}

.channel-theme-icon {
  color: var(--dark-text-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Dark theme style overrides for Stream components */
:root {
  --str-chat__primary-color: var(--dark-accent);
  --str-chat__active-primary-color: var(--dark-accent-hover);
  --str-chat__message-background-color-me: var(--dark-message-me);
  --str-chat__message-background-color: var(--dark-message-other);
  
  /* Override Stream Chat variables for dark theme */
  --str-chat__channel-bg-color: var(--dark-bg-secondary);
  --str-chat__message-text-color: var(--dark-text-primary);
  --str-chat__message-text-color-me: white;
  --str-chat__message-sender-text-color: var(--dark-text-secondary);
  --str-chat__avatar-background-color: var(--dark-card-bg);
  --str-chat__message-reaction-background-color: var(--dark-card-bg);
  --str-chat__message-reaction-background-color-active: var(--dark-accent);
}

/* Ensure the call controls stick to the bottom with dark theme */
.str-video__call-controls {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(8px);
}

/* Dark theme participant list styling */
.participants-container.dark-theme-participants {
  background-color: var(--dark-bg-secondary);
}

.dark-theme-participants .str-video__participant-list {
  padding: 12px;
}

.dark-theme-participants .str-video__participant-list-item {
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 8px 12px;
  background-color: var(--dark-card-bg);
  color: var(--dark-text-primary);
}

.dark-theme-participants .str-video__participant-list-item:hover {
  background-color: var(--dark-hover);
}

/* Speaker view adjustments for dark theme */
.str-video__speaker-layout {
  width: 100%;
  height: 100%;
  background-color: var(--dark-bg-primary);
}

/* Dark theme message list improvements */
.str-chat__message-list {
  padding: 16px;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: var(--dark-bg-secondary);
}

.str-chat__message-list-scroll {
  padding-bottom: 8px;
}

/* Message styling for dark theme */
.str-chat__message {
  margin-bottom: 12px;
}

.str-chat__message-inner {
  border-radius: 16px;
  padding: 8px 12px;
}

/* Chat message bubbles styling for dark theme */
.dark-theme .str-chat__message-inner,
.dark-theme-message .str-chat__message-inner {
  background: #1E1E1E;
  color: white;
  border: 1px solid #2D3748;
}

/* Current user's messages */
.dark-theme .str-chat__message--me .str-chat__message-inner,
.dark-theme-message .str-chat__message--me .str-chat__message-inner {
  background: #2563EB;
  color: white;
  border: none;
}

/* Other user's messages */
.dark-theme .str-chat__li--incoming .str-chat__message-inner,
.dark-theme-message .str-chat__li--incoming .str-chat__message-inner {
  background: #1E1E1E;
  color: white;
}

/* Force chat bubble colors for all contexts */
.str-chat__message-inner {
  background: #1E1E1E !important;
  color: white !important;
}

.str-chat__message--me .str-chat__message-inner {
  background: #2564eb41 !important;
  color: white !important;
}

/* Chat container background */
.chat-container, 
.dark-theme .str-chat,
.dark-theme .str-chat__container,
.dark-theme .str-chat__main-panel,
.dark-theme .str-chat__channel,
.dark-theme .str-chat__list {
  background-color: #121212 !important;
}

/* Chat message list background */
.str-chat__message-list,
.str-chat__virtual-list,
.str-chat__virtual-list-container,
.str-chat__main-panel {
  background-color: #121212 !important;
}

/* Thread styling for dark theme */
.dark-theme .str-chat__thread {
  background-color: var(--dark-bg-secondary);
  border-left: 1px solid var(--dark-border);
}

.dark-theme .str-chat__thread-header {
  background-color: var(--dark-bg-tertiary);
  border-bottom: 1px solid var(--dark-border);
}

.dark-theme .str-chat__square-button {
  background-color: var(--dark-card-bg);
  color: var(--dark-text-primary);
}

.dark-theme .str-chat__square-button:hover {
  background-color: var(--dark-hover);
}

/* Stream Video dark theme overrides */
.dark-theme .str-video__participant {
  background-color: var(--dark-bg-tertiary);
  border: 1px solid var(--dark-border);
}

.dark-theme .str-video__participant-placeholder {
  background-color: var(--dark-card-bg);
}

.dark-theme .str-video__menu {
  background-color: var(--dark-card-bg);
  border: 1px solid var(--dark-border);
}

.dark-theme .str-video__menu-item {
  color: var(--dark-text-primary);
}

.dark-theme .str-video__menu-item:hover {
  background-color: var(--dark-hover);
}

.dark-theme .str-video__call-controls-button {
  background-color: var(--dark-card-bg);
  color: var(--dark-text-primary);
}

.dark-theme .str-video__call-controls-button:hover {
  background-color: var(--dark-hover);
}

.dark-theme .str-video__call-controls-button--leave {
  background-color: #EF4444;
  color: white;
}

/* Animation-specific styles */
.fadeIn {
  animation: fadeIn 0.3s ease forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.slideIn {
  animation: slideIn 0.3s ease forwards;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.zoomIn {
  animation: zoomIn 0.2s ease forwards;
}

@keyframes zoomIn {
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* Responsive adjustments for dark theme */
@media (max-width: 768px) {
  .main-video-area.with-sidebar {
    display: none;
  }
  
  .call-sidebar {
    width: 100%;
  }
  
  .floating-controls {
    top: 8px;
    right: 8px;
  }
  
  .control-btn {
    padding: 6px;
  }
  
  .dark-theme .str-video__call-controls {
    padding: 8px;
  }
}